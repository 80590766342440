import React from 'react'
import { Link, graphql } from 'gatsby'

import Environment from '../lib/Environment'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import epiarcIcon from '../components/epiarc-icon.svg'
import CopyContainer from '../components/CopyContainer'
import LinkButton from '../components/LinkButton'
import {WebsiteIcon} from '@epi/epi-ui'

import './index.scss'


class Index extends React.Component {

  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout root={true} location={this.props.location} title={siteTitle}>
        <SEO
          title=""
        />

        <div className="Index">
          

          <CopyContainer>
            <h1>The archetypal name system</h1>
            <p>The Epiarc is a Web consensus network for discovering how we name things.</p>
            
            <p><strong>Emergent.</strong> Names, social dynamics, and usage patterns emerge from the ground up on the Epiarc. There is no central authority or master architect.</p>
            
            <p><strong>Contextual.</strong> The Epiarc lets us name things in context, in a way that is natural to us.</p>
            
            <p><strong>Open.</strong> The Epiarc is free for everyone to use and contribute to.</p>
            
            <div className="Index-buttons">
              <p>
                <Link to="/concepts"><WebsiteIcon name="book" />Read the concepts</Link>
              </p>
            </div>
          </CopyContainer>
        </div>
        
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
